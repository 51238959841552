<script lang="ts" setup>
defineProps<{
  burger?: boolean;
  dark?: boolean;
}>();
</script>

<template>
  <BaseFixedButton
    class="modalButton"
    :dark="dark"
    :class="{ modalButton_opened: !burger }"
  >
    <span class="modalButton__line" />
    <span class="modalButton__line" />
    <span class="modalButton__line" />
  </BaseFixedButton>
</template>

<style lang="scss">
.modalButton {
  $self: &;

  &_opened {
    &::before {
      transition: opacity 0.3s ease;
      opacity: 0;
    }

    #{$self}__line {
      width: 35px;
      background-color: #ffffff;
      transition:
        transform 0.3s ease,
        width 0.3s ease,
        background-color 0.3s ease;

      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &__line {
    height: 2px;
    background-color: #121212;
    width: 40px;
    position: absolute;
    border-radius: 1px;
    top: 50%;
    left: 50%;
    transition:
      transform 0.3s ease,
      width 0.3s ease,
      background-color 0.3s ease 0.2s;
    transform-origin: 50% 50%;

    &:nth-child(1) {
      transform: translate(-50%, calc(-50% - 10px));
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%);
    }

    &:nth-child(3) {
      transform: translate(-50%, calc(-50% + 10px));
    }

    @include mq('sm') {
      width: 32px;

      &:nth-child(1) {
        transform: translate(-50%, calc(-50% - 8px));
      }

      &:nth-child(3) {
        transform: translate(-50%, calc(-50% + 8px));
      }
    }
  }
}
</style>
