<script lang="ts" setup>
defineProps<{
  dark?: boolean;
  count?: number;
}>();
</script>

<template>
  <BaseFixedButton :dark="dark" class="userButton">
    <SvgoSimpleUser filled :font-controlled="false" class="userButton__icon" />
  </BaseFixedButton>
</template>

<style lang="scss">
.userButton {
  & &__icon {
    height: 44px;
    width: 25px;
    transition: opacity 0.3s ease;
    position: absolute;
    display: block;
    color: #fff;
    margin-bottom: 0;
    transform: translateY(-5%);
  }

  @include mq('sm') {
    & &__icon {
      height: 32px;
      width: 24px;
    }
  }
}
</style>
