<script lang="ts" setup>
defineProps<{
  dark?: boolean;
}>();
defineSlots<{
  default: () => VNode[];
}>();
</script>

<template>
  <button class="baseFixedButton" :class="{ baseFixedButton_dark: dark }">
    <slot />
  </button>
</template>

<style lang="scss">
.baseFixedButton {
  $self: &;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  padding: 0;
  border-radius: 50%;
  z-index: 11;
  border: none;
  cursor: pointer;
  outline: none;
  background: none;
  position: relative;
  transition: filter 0.3s ease;

  &::before {
    content: '';
    height: inherit;
    width: inherit;
    display: block;
    background-color: #ffffff;
    border: 1px solid #fff;
    border-radius: inherit;
    transition:
      opacity 0.3s ease 0.2s,
      background-color 0.3s ease;
    box-sizing: border-box;
  }

  &:hover::before {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &_dark {
    filter: invert(1);

    &::before {
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }

  @include mq('md') {
    width: 60px;
    height: 60px;
  }

  @include mq('sm') {
    width: 56px;
    height: 56px;
  }
}
</style>
